export default {
  'user.Profile': 'Профиль',
  'user.ChPass': 'Смена пароля',
  'user.nickname': 'ФИО',
  'user.password': 'Пароль',
  'user.password.current': 'Текущий',
  'user.password.new': 'Новый',
  'user.password.confirm': 'Подтверждение',
  'user.date': 'Дата',
  'user.Options': 'Настройки',
  'user.Options.blank': 'Открывать файлы полёта в новой вкладке',
  'user.password.validate': 'Пароль должен содержать не менее 8 символов, состоять из прописных и строчных латинских букв, цифр и спец.символов',
  'user.password.confirm.error': 'Пароли не совпадают!',
  'user.password.current.empty': 'Введите текущий пароль',
  'user.password.new.empty': 'Введите новый пароль',
  'user.password.confirm.empty': 'Подтвердите новый пароль',
  'user.password.change.success': 'Пароль успешно изменен',
  'user.password.change.current.error': 'Текущий пароль не совпадает',
  'user.password.change.server_error': 'Попробуйте позже',
}
