import {
  Client,
  Provider as UrqlLibProvider,
  dedupExchange,
  fetchExchange,
} from 'urql';
import { authExchange } from '@urql/exchange-auth';
import type { CurrentUser } from '@/app';
import { getLocale } from 'umi';

export const CurrentUserKey = 'currentUser';
export const CurrentOptionsKey = 'currentOptions';
export const UserLoginPath = '/login/';

const authMiddleware = authExchange<CurrentUser>({
  addAuthToOperation: ({ authState, operation }) => {
    //console.log('addAuthToOperation: authState', authState);

    if (!authState?.token) {
      //console.log('addAuthToOperation: no authState, do nothing');
      return operation;
    }

    //console.log('addAuthToOperation: adding token [' + authState?.token + '] to request');

    const fetchOptions =
      typeof operation.context.fetchOptions === 'function'
        ? operation.context.fetchOptions()
        : operation.context.fetchOptions || {};
    fetchOptions.credentials = !authState.AD ? 'same-origin' : 'include';
    fetchOptions.headers = !authState.AD
      ? { Authorization: `Bearer ${authState.token}` }
      : {};

    return {
      ...operation,
      context: {
        ...operation.context,
        fetchOptions: {
          ...fetchOptions,
          headers: {
            ...fetchOptions.headers,
          },
        },
      },
      variables: {
        ...operation.variables,
        lang: getLocale(),
      }
    };
  },
  willAuthError: ({ authState }) => {
    return !authState;
  },
  didAuthError: ({ error }) => {
    const didFailed =
      error.response && error.response.status == 401 ? true : false;

    if (didFailed) {
      //console.log('didAuthError: errors: ',error.graphQLErrors)
      //console.log('didAuthError: request failed because of auth, removing current user from localStorage', error);

      localStorage.removeItem(CurrentUserKey);
    }
    return didFailed;
  },
  getAuth: async ({ authState }) => {
    if (!authState) {
      //console.log('getAuth: no authState provided, looking for it in localStorage')
      const currentUserJson = localStorage.getItem(CurrentUserKey);
      //console.log('getAuth: got this from localStorage: ', currentUserJson);

      if (currentUserJson) {
        const user = JSON.parse(currentUserJson) as CurrentUser;
        return user;
      }
    }

    if (window.location.pathname !== UserLoginPath) {
      window.location.pathname = UserLoginPath;
    }

    // failed to get authState, user needs to log in again
    return null;
  },
});

export const urqlClient = new Client({
  url: API + GRAPHQL,
  exchanges: [dedupExchange, authMiddleware, fetchExchange],
});

export const UrqlClientProvider = ({ children }: any) => (
  <UrqlLibProvider value={urqlClient}>{children}</UrqlLibProvider>
);
