export default {
  'order.Title': 'Title',
  'order.Datetime': 'Date',
  'order.Description': 'Description',
  'order.Creator': 'Creator',
  'order.Status': 'Status',
  'order.Add': 'Add',
  'order.View': 'View',
  'order.Save': 'Save',
  'order.Area': 'Select area',
  'order.Files': 'Files',
  'order.Flights': 'Flights',
  'order.Attachments': 'Attachments',

  'order.status.Canceled': 'Canceled',
  'order.status.Inwork': 'Inwork',
  'order.status.Rejected': 'Rejected',

  'order.error.Save': 'Order not saved!',
  'order.prompt.flight': 'Select flights',

  'order.Datetime.required': 'Date required',
  'order.Description.required': 'Description required',
};
