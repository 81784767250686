export default {
  violation: 'Происшествие',
  violations: 'Происшествия',
  'menu.violations': 'Происшествия',
  'menu.vlt_view': 'Происшествие',
  'menu.vlt_list': 'Список происшествий',
  'module.violations': 'Происшествия',
  'report.Violations': 'Происшествия',
  'violation.name': 'Наименование',
  'violation.pic': 'Изображение',
  'violation.datetime': 'Дата и время',
  'violation.center': 'Координаты центра',
  'violation.target': 'Координаты цели',
  'violation.type': 'Тип',
  'violation.dtype': 'Способ обнаружения',
  'violation.current_status': 'Текущий статус',
  'violation.status': 'Статус',
  'violation.status.null': 'Не установлен',
  'violation.change_status': 'Сменить статус',
  'violation.relevance': 'Приоритет',
  'violation.comment': 'Комментарий',
  'violation.comments': 'Комментарии',
  'violation.vlt_types': 'Все типы происшествий',
  'violation.vlt_statuses': 'Все статусы',
  'violation.vlt_relevances': 'Сменить приоритет',
  'violation.list': 'Список происшествий',
  'violation.report': 'Выгрузить в Excel',
  'violation.search_results_count': 'Происшествий, удовлетворяющих фильтру',
  'violation.bnt-photo': 'Фото',
  'violation.bnt-video': 'Видео',
  'violation.bnt-cdreport': 'Ортофотоплан',
  'violation.Heatmap': 'Тепловая карта',
  'violation.Cluster': 'Числовая карта',
  'violation.heatmap_type': 'Все типы данных',
  'violation.heatmap-matches': 'Количество совпадений',
  'violation.heatmap-type-cd': 'Изменения',
  'violation.heatmap-type-vlt': 'Происшествия',
  'violation.UploadVltImages': 'Нажмите или перенесите файлы в эту область, чтобы загрузить',

  'violation.errorimage.type': 'Изображения можно загружать только в формате JPG/PNG!',
  'violation.errorimage.size': 'Маскимальный размер файла 20МБ',

  'violation.show_all_comments': 'Показать комментарии',

  'violation.context.add': 'Добавить',
  'violation.context.addVlt': 'Добавить происшествие',
  'violation.context.add.OK': 'Добавить',
  'violation.context.add.Cancel': 'Отмена',
  'violation.context.create.success': 'Происшествие создано!',
  'violation.context.create.pic.required': 'Необходимо изображение!',
  
  'violation.zviolations': 'Происшествия онлайн',
};
