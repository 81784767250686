export default {
  'ofp.name': 'Name',
  'ofp.title': 'Orthomosaic',
  'ofp.datetime': 'Date',
  'ofp.ofpicons': 'Icons',
  'ofp.Compare': 'Compare',
  'ofp.Action': 'Actions',
  'ofp.OFP_MISSING': 'Orthomosaic deleted',
  'ofp.Cdreport.button': 'GeoDynamics',
  'ofp.Cdreport.SearchArea': 'Report area',
  'ofp.Cdreport.Classes': 'Classes',
  'ofp.Cdreport.Segments': 'Segmentation',
  'ofp.Cdreport.Open': 'PDF',
  'ofp.cdreport.name': 'Title',
  'ofp.cdreport.ofp_id_1': 'Left window CD',
  'ofp.cdreport.ofp_id_2': 'Right window CD',
  'ofp.сdreport.Confirm update': 'Sure in the selected orthophotos?',
  'ofp.сdreport.VIEW_PDF': 'View PDF',
  'ofp.сdreport.VIEW_OFP': 'View on orthomosaic', 
  'ofp.Orthomosaic processing': 'Processing GeoTIFF',
};
