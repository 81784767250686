import { Button, Result } from 'antd';
import React from 'react';
import { history, FormattedMessage } from 'umi';

const ForbiddenPage: React.FC = () => (
  <Result
    status="403"
    title="403"
    subTitle={<FormattedMessage id="403" defaultMessage='Forbidden' />}
    extra={
      <Button type="primary" onClick={() => history.push('/')}>
        <FormattedMessage id="Back Home" defaultMessage="Back Home" />
      </Button>
    }
  />
);

export default ForbiddenPage;
