export default {
  'massload.BACK_TO_UPLOAD': 'To upload data',
  'massload.File Name': 'File Name',
  'massload.Created At': 'Created At',
  'massload.Type': 'File type',
  'massload.Actions': 'Actions',
  'massload.DownloadServiceLink': 'Download agent',
  'massload.NoService': 'No agent',
  'massload.loading.telemetry': 'Loading telemetry',
};
