export default {
  'dtoe.CreateTask': 'Add to a dtoe queue',
  'dtoe.Submit': 'Submit',
  'dtoe.Polygon': 'Select an polygon',
  'dtoe.Extent': 'Select an extent',
  'dtoe.Levels': 'Zoom levels:',
  'dtoe.Levels.required': 'You must select at least one level',
  'dtoe.Source': 'Source:',
  'dtoe.Source.required': 'You must select a source',
  'dtoe.Source.ArcGIS': 'ArcGIS',
  'dtoe.Source.Bing': 'Bing',
  'dtoe.Source.Google': 'Google',
  'dtoe.Source.Yandex': 'Yandex',
  'dtoe.TileType': 'Data type:',
  'dtoe.TileType.required': 'You must select a data type',
  'dtoe.TileType.aer': 'Base rastr layer',
  'dtoe.TileType.dem': 'Heightmap',
  'dtoe.TileType.vgm': 'Vegetation',
  'dtoe.TileType.ofp': 'Orthomosaic',
  'dtoe.error.Submit': 'Submitting was failed, please try again.',
  'dtoe.Submit.Success': 'The dtoe task was successfully queued.',
  'dtoe.Cancel': 'Cancel',
  'dtoe.State.unknown': 'Unknown',
  'dtoe.State.undefined': 'Unknown',
  'dtoe.State.pending': 'Pending',
  'dtoe.State.executing': 'Executing',
  'dtoe.State.complete': 'Complete',
  'dtoe.State.cancelling': 'Cancelling',
  'dtoe.State.cancelled': 'Cancelled',
  'dtoe.Cancellation.Success': 'Task canceled successfully',
  'dtoe.error.Cancellation': 'Cancellation was failed, please try again.',
  'dtoe.Creator': 'Creator',
  'dtoe.Created': 'Created',
  'dtoe.State': 'State',
  'dtoe.Progress': 'Progress',
  'dtoe.Description': 'Description:',
  'dtoe.Description.max': 'Description could not be longer than 250 characters.',
  'dtoe.Or': 'or',
  'dtoe.error.EmptyPolygon': 'You must select a zone of interest.',
  'dtoe.Button.Back': 'Back',
  'dtoe.Upload': 'Upload',
  'dtoe.UploadTitle': 'Upload:',
  'dtoe.error.EmptyFormats': 'You must select at list one output format.',
  'dtoe.Layer': 'Layer:',
  'dtoe.Download': 'Download',
}
