export default {
    'asset.name': 'Name',
    'asset.hardwareId': 'HardwareId',
    'asset.type': 'Type',
    'asset.type.placeholder': 'Select type',
    'asset.project': 'Project',
    'asset.lastLocation': 'Coordinates',
    'asset.lastOnline': 'Online',
    'asset.actions': 'Actions',
    'asset.details': 'Details',
    'asset.car': 'Car',
    'asset.uav': 'UAV',
    'asset.gcs': 'GCS',
    'asset.beacon': 'Beacon',
    'asset.4z1local': '4z1 local',
    'asset.geoGorizont': 'GeoGorizont',
    'asset.geoGorizontMobile': 'GeoGorizont Mobile',
    'asset.incident': 'Incident',
    'asset.ptz': 'Camera',
    'asset.noDataWasReceivedYet': 'No data',
    'assets.allAssets': 'All assets',
    'assets.createAsset': 'Create asset',
    'assets.noLocationData': 'No coordinates',
    'assets.comment.label': 'Comment',
    'assets.alwaysStreamVideo.label': 'Always stream',
    'assets.licensePlate.label': 'License plate',
    'assets.model.label': 'Model',
    'assets.lastServiceDate.label': 'Last service date',
    'assets.responsiblePersonContacts.label': 'Personal contact',
    'assets.unknown.label': 'Unknown field',
    
    'assets.edit.title': 'Edit asset',
    'assets.selectProject': 'Select project',
  
    'asset.details.currentStatus': "Current status",
    'asset.details.historicalData': "History data",
    'asset.details.sendCommands': "Send commands",
    'asset.details.streaming': "Streaming",
    'asset.details.doYouWantToDelete': 'Sure to delete?',
    'asset.details.messageWasNotAdded': "Message was not added",
    'asset.details.messageAdded': "Message added",
    'asset.details.loading': 'Loading...',
    'asset.details.editAsset': 'Edit asset',
    'asset.details.deleteAsset': 'Delete asset',
    'asset.details.status': 'Status',
    'asset.details.id': 'ID',
    'asset.details.name': 'Name',
    'asset.details.type': 'Type',
    'asset.details.project': 'Project',
    'asset.details.group': 'Group',
    'asset.details.information': 'Information',
    'asset.details.connectedUAVs': 'Connected UAVs',
    'asset.details.capabilities': 'Capabilities',
    'asset.details.optionalProperties': 'Optional properties',
    'asset.details.model': 'Model',
    'asset.details.lastServiceDate': 'Last service date',
    'asset.details.responsible': 'Responsible',
    'asset.details.licensePlate': 'License plate',
    'asset.details.comment': 'Comment',
    'asset.details.mileage': 'Mileage',
    'asset.details.today': 'Today',
    'asset.details.thisMonth': 'This month',
    'asset.details.total': 'Total',
    'asset.details.message': 'Message',
    'asset.details.typeMessageHere': 'Type message here',
    'asset.details.sendMessage': 'Send message',
    'asset.details.sentCommands': 'Sent commands',
    'asset.details.otherCommands': 'Other commands',
    'asset.details.startVideoStreaming': 'Start Streaming',
    'asset.details.stopVideoStreaming': 'Stop Streaming',
  
    'assets.historicalData.telemetryData': 'Telemetry data',
    'assets.historicalData.timestamp': 'Time',
    'assets.historicalData.altitude': 'Altitude',
    'assets.historicalData.speed': 'Speed',
    'assets.historicalData.location': 'Coordinates',
    'assets.historicalData.altitudeM': 'Altitude, m',
    'assets.historicalData.course': 'Course, °',
    'assets.historicalData.speedKmH': 'Speed, km/h',
    'assets.historicalData.inAir': 'inAir',
    'assets.historicalData.yes': 'Yes',
    'assets.historicalData.no': 'No',
    'assets.historicalData.na': 'No data',
    'assets.historicalData.pilotingMode': 'Piloting mode',
    'assets.historicalData.diagnosticsFlags': 'Diagnostics',
    'assets.historicalData.from': 'From date',
    'assets.historicalData.filters': 'Filters',
    'assets.historicalData.today': 'Today',
    'assets.historicalData.thisMonth': 'This month',
    'assets.historicalData.getData': 'Get data',
    'assets.historicalData.charts': 'Charts',
    'mapBox.sattelite': 'Sattelite',
    'assets.historicalData.noLocationData': 'No coordinates',
  };
  