export default {
  'ftp.hint.unknown': 'Data could be uploaded to FTP',
  'ftp.hint.in_progress': 'Uploading to FTP is in progress',
  'ftp.hint.done': 'Data is already uploaded to FTP',
  'ftp.hint.failed': 'Failed to upload data to FTP',

  'ftp.userLoginGCS': 'User Login GCS',
  'ftp.companyCode': 'Company Code',
  'ftp.viewShot': 'View Shot',
  'ftp.flightDate': 'Flight date',
  'ftp.routeNumber': 'Route №',
  'ftp.flightNumber': 'Flight №',
  'ftp.teamNumber': 'Team Id',
  'ftp.cameraModel': 'Camera model',
  'ftp.focus': 'Focal length (mm)',
  'ftp.send_to_ftp': 'Send to FTP',
  'ftp.send_to_ftp.title': 'Upload to FTP',
  'ftp.submit.error': 'Request failed. Check entered data and try once again',
  'ftp.submit.success': 'Success',

  'ftp.validation.required_value': 'Please, enter a value',
  'ftp.validation.required_file': 'Please, choose file',
  'ftp.validation.max_length': 'Too many characters entered. Max - ',
  'ftp.validation.permitted_chars': 'Only latin characters, digits, dash and dots allowed',
  'ftp.validation.only_numbers_permitted': 'Only digits, dash and dots allowed',

  'ftp.error.no_flight': 'Something went wrong. Flight not found',

  'ftp.form.telemetryTitle': 'Loading telemetry',
  'ftp.form.uploadFile': 'upload file',
  'ftp.form.send': 'Send telemetry file',
  'ftp.mm': 'mm',

};
