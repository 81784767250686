export default {
  'order.Title': 'Наименование',
  'order.Datetime': 'Дата',
  'order.Description': 'Описание',
  'order.Creator': 'Инициатор',
  'order.Status': 'Статус',
  'order.Add': 'Подать заявку',
  'order.View': 'Просмотр заявки',
  'order.Save': 'Сохранить',
  'order.Area': 'Выбрать область',
  'order.Files': 'Файлы',
  'order.Flights': 'Полёты',
  'order.Attachments': 'Прикрепить файлы',

  'order.status.Canceled': 'Отменить',
  'order.status.Inwork': 'Принять',
  'order.status.Rejected': 'Отказать',

  'order.error.Save': 'Заявка не сохранена!',
  'order.prompt.flight': 'Для выполения заявки, выберите полёт(ы)',


  'order.Datetime.required': 'Необходимо выбрать дату',
  'order.Description.required': 'Необходимо заполнить описание',
};
