export default {
  'flight.name': 'Title',
  'flight.routes': 'All routes',
  'flight.users': 'All users',
  'flight.showAll': 'Show all',
  'flight.edit': 'Edit flight',
  'flight.NewFlight': 'New flight',

  'flight.flights_update_success': 'Update success',
  'flight.flights_update_error': 'Update error',
  'flight.empty_new_flight': 'New flight cannot be empty',

  'flight.NO_FLIGHT': 'Flight not found',
  'flight.Edit_Name_Flight': 'Edit flight\'s name',
  'flight.flights_delete_success': 'Flight deleted',
};
