export default {
  'mark.Title': 'Mark',
  'mark.name': 'Name',
  'mark.radius': 'Radius (km)',
  'mark.color_radius': 'Color radius',

  'mark.context.Mark': 'Mark',
  'mark.context.add': 'Create',
  'mark.context.addMark': 'Create mark',
  'mark.context.editMark': 'Edit mark',
  'mark.context.delMark': 'Delete mark',
  'mark.context.button.OK': 'Create',
  'mark.context.button.EDIT': 'Edit',
  'mark.context.button.Cancel': 'Cancel',
  'mark.context.button.DELETE': 'Delete',
  'mark.context.create.success': 'Mark created!',
  'mark.context.update.success': 'Mark updated!',
  'mark.context.delete.success': 'Mark deleted!',
  'mark.context.create.name.required': 'Name is required!',
  'mark.context.delete.confirm' : 'Are you sure to delete this mark?',
};
