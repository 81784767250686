export default {
  'layer.vprf': 'Воздушное пространство',
  'layer.vprf.zones': 'Зоны и районы',
  'layer.vprf.rz': 'Зоны ограничения полетов',
  'layer.vprf.zz': 'Запретные зоны',
  'layer.vprf.dz': 'Опасные зоны',
  'layer.vprf.notam': 'НОТАМ',
  'layer.vprf.ro': 'Режимы и ограничения',
  'layer.vprf.mdp': 'МДП',
  'layer.vprf.rpi': 'РПИ',
  'layer.vprf.sectors': 'Сектора',
  'layer.traffic': 'Трафик',
  'layer.traffic.air': 'Воздушный',
  'layer.traffic.sea': 'Водный',
};
