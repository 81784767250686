import { Settings as LayoutSettings } from '@ant-design/pro-layout';

const Settings: LayoutSettings & {
  pwa?: boolean;
  siderColor: string,
} = {
  navTheme: 'dark',
  primaryColor: '#9a794c',
  siderColor:'#314657',
  layout: 'side',
  contentWidth: 'Fluid',
  fixedHeader: true,
  fixSiderbar: true,
  colorWeak: false,
  title: '4Z1',
  pwa: false,
  headerRender: false,
  footerRender: false,
  iconfontUrl: '',
};

export default Settings;
