export default {
  'gaz.title': 'Gas analyzer',
  'gaz.name': 'Gas',
  'gaz.radius size': 'radius size',
  'gaz.blur size': 'blur size',
  'gaz.threshold': 'threshold',
  'gaz.All_gazes': 'All for the period',
  'gaz.Cluster': 'Cluster',
  'gaz.Heatmap': 'Heatmap',
};
