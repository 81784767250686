export default {
  'online.layers': 'Онлайн метки',
  'online.height': 'Высота',
  'online.wind_ms': 'Ветер',
  'online.wind_dir': 'Направление ветра',
  'online.metr': 'м',
  'online.metr_seconds': 'м/с',

  'online.MultiStream': 'Мультистрим',

  'online.assetsFilter.onlyActive': 'Показывать только активные объекты',
  'online.assetsFilter.onlyStreaming': 'Показывать только объекты с видеотрансляцией',
  'online.assetsFilter.onlyRevoked': 'Показывать только "Отозванные" устройства',
  'online.assetsFilter.assetType': 'Тип объекта',

  'online.assetTypes.allAssetTypes': 'Все типы',
  'online.assetTypes.car.shortname': 'Автомобиль',
  'online.assetTypes.car.fullname': 'Автомобиль',
  'online.assetTypes.uav.shortname': 'БВС',
  'online.assetTypes.uav.fullname': 'Беспилотное воздушное судно (БВС)',
  'online.assetTypes.gcs.shortname': 'НСУ',
  'online.assetTypes.gcs.fullname': 'Наземная станция управления (НСУ)',
  'online.assetTypes.incident.shortname': 'Метка',
  'online.assetTypes.incident.fullname': 'Метка',
  'online.assetTypes.beacon.shortname': 'Маяк',
  'online.assetTypes.beacon.fullname': 'Маяк',
  'online.assetTypes.geographics.shortname': 'ГеоГоризонт',
  'online.assetTypes.geographics.fullname': 'ГеоГоризонт',
  'online.assetTypes.localServers': '4Z1',
  'online.assetTypes.ptz.fullname': 'Камера',

  'online.assetCard.connected': 'Подключен',
  'online.assetCard.disconnected': 'Отключен',
  'online.assetCard.canSendGPS': 'Отправка GPS',
  'online.assetCard.canStreamVideo': 'Показ видео',
  'online.assetCard.canExecuteCommands': 'Выполнение команд',
  'online.assetCard.hardwareID': 'Идентификатор',
  'online.assetCard.warnings': 'Предупреждения',
  'online.assetCard.gcs': 'НСУ',
  'online.assetCard.state': 'Положение',
  'online.assetCard.inAir': 'В воздухе',
  'online.assetCard.grounded': 'На земле',
  'online.assetCard.mode': 'Режим',
  'online.assetCard.engineTemp': 'Температура',
  'online.assetCard.activeUAV': 'Активное БВС',
  'online.assetCard.сonnectedUAVs': 'Подключенные БВС',
  'online.assetCard.speed': 'Скорость',
  'online.assetCard.kmh': 'км/ч',
  'online.assetCard.course': 'Курс',
  'online.assetCard.location': 'Координаты',
  'online.assetCard.altitude': 'Высота',
  'online.assetCard.lastUpdated': 'Обновлено',
  'online.assetCard.last1h': '1 час',
  'online.assetCard.last8h': '8 часов',
  'online.assetCard.last24h': 'Сутки',
  'online.assetCard.showTrack': 'Показать маршрут',
  'online.assetCard.showVideoStream': 'Показать видео',
  'online.assetCard.changeCoordinates': 'Изменить координаты',
  'online.assetCard.showVideoStreamFromGCS': 'Показать видео с НСУ',
  'online.assetCard.openDetailsPage': 'Подробная информация',

  'online.multiVideoPlayers.actions.zoomIn': "Приблизить",
  'online.multiVideoPlayers.actions.zoomOut': "Уменьшить",
  'online.fieldOfView': 'Зоны видимости камер',
  'online.fieldOfViewToggle.show': 'Отобразить область видимости камеры',
  'online.fieldOfViewToggle.hide': 'Скрыть область видимости камеры',

};
