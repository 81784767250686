export default {
  'module.maps': 'Карты',
  'module.video': 'Видео',
  'module.videos': 'Видео',
  'module.imagetile': 'Архив фото',
  'module.imagetiles': 'Архивы фото',
  'module.flights': 'Полеты',
  'module.ofps': 'Ортофотопланы',
  'module.routes': 'Маршруты',
  'module.tiles': 'Слои',
  'module.onlines': 'Онлайн трансляции',
  'module.gaz': 'Газоанализатор',
  'module.gazes': 'Газоанализатор',
  'module.massload': 'Загрузка',
  'module.orders': 'Заявки',
  'module.order': 'Заявка',
  'module.marks': 'Метки',
};
