export default {
  'layer.vprf': 'Airspace',
  'layer.vprf.zones': 'Zones and areas',
  'layer.vprf.rz': 'Restricted zones',
  'layer.vprf.zz': 'Closed areas',
  'layer.vprf.dz': 'Danger zones',
  'layer.vprf.notam': 'NOTAM',
  'layer.vprf.ro': 'Restrictions',
  'layer.vprf.mdp': 'MDP',
  'layer.vprf.rpi': 'RPI',
  'layer.vprf.sectors': 'Sectors',
  'layer.traffic': 'Traffic',
  'layer.traffic.air': 'Air',
  'layer.traffic.sea': 'Sea',
};
