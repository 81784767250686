export default {
  violation: 'Incident',
  violations: 'Incidents',
  'menu.vlt_view': 'Incident',
  'menu.vlt_list': 'List of incidents',
  'menu.violations': 'Incidents',
  'module.violations': 'Incidents',
  'report.Violations': 'Incidents',
  'violation.name': 'Title',
  'violation.pic': 'Image',
  'violation.datetime': 'Datetime',
  'violation.center': 'Center coordinates',
  'violation.target': 'Target coordinates',
  'violation.type': 'Type',
  'violation.dtype': 'Detection method',
  'violation.current_status': 'Current status',
  'violation.status': 'Status',
  'violation.status.null': 'Status undefined',
  'violation.change_status': 'Change status',
  'violation.relevance': 'Relevance',
  'violation.comment': 'Comment',
  'violation.comments': 'Comments',
  'violation.vlt_types': 'Select type',
  'violation.vlt_statuses': 'Select status',
  'violation.vlt_relevances': 'Select relevance',
  'violation.list': 'Incident list',
  'violation.report': 'Export to Excel',
  'violation.search_results_count': 'Incidents matching the filter',
  'violation.bnt-photo': 'To photo',
  'violation.bnt-video': 'To video',
  'violation.bnt-cdreport': 'Orthomosaic',
  'violation.Heatmap': 'Heatmap',
  'violation.Cluster': 'Cluster',
  'violation.heatmap_type': 'All types of data',
  'violation.heatmap-matches': 'Matches',
  'violation.heatmap-type-cd': 'Changes',
  'violation.heatmap-type-vlt': 'Incidents',
  'violation.UploadVltImages': 'Click or drag files to this area to upload',

  'violation.errorimage.type': 'Images can only be uploaded in JPG/PNG format!',
  'violation.errorimage.size': 'Max file size 20МБ',

  'violation.show_all_comments': 'Show all comments',

  'violation.required.name': 'Input tite',
  
  'violation.context.add': 'Create',
  'violation.context.addVlt': 'Create incident',
  'violation.context.add.OK': 'Create',
  'violation.context.add.Cancel': 'Cancel',
  'violation.context.create.success': 'incident created!',
  'violation.context.create.pic.required': 'Image required!',
};
