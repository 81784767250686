export default {
  'module.maps': 'Maps',
  'module.video': 'Video',
  'module.videos': 'Videos',
  'module.imagetile': 'Photo archive',
  'module.imagetiles': 'Photo archives',
  'module.flights': 'Flights',
  'module.ofps': 'Orthomosaics',
  'module.routes': 'Routes',
  'module.tiles': 'Tiles',
  'module.onlines': 'Streams',
  'module.gaz': 'Gas analyzer',
  'module.gazes': 'Gas analyzers',
  'module.massload': 'Upload data',
  'module.orders': 'Orders',
  'module.order': 'Order',
  'module.marks': 'Marks',
};
