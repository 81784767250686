export default {
  'asset.name': 'Наименование',
  'asset.hardwareId': 'Идентификатор',
  'asset.type': 'Тип',
  'asset.type.placeholder': 'Выбрать тип объекта',
  'asset.project': 'Проект',
  'asset.lastLocation': 'Координаты',
  'asset.lastOnline': 'Онлайн',
  'asset.actions': 'Действия',
  'asset.details': 'Детали',
  'asset.car': 'Автомобиль',
  'asset.uav': 'БВС',
  'asset.gcs': 'НСУ',
  'asset.beacon': 'Маяк',
  'asset.4z1local': '4z1 Local',
  'asset.geoGorizont': 'ГеоГоризонт',
  'asset.geoGorizontMobile': 'ГеоГоризонт мобильный',
  'asset.incident': 'Метка',
  'asset.ptz': 'Камера',
  'asset.noDataWasReceivedYet': 'Данные еще не получены',
  'assets.allAssets': 'Объекты',
  'assets.createAsset': 'Новый объект',
  'assets.noLocationData': 'Координаты отсутствуют',
  'assets.comment.label': 'Коментарий',
  'assets.alwaysStreamVideo.label': 'Всегда транслировать видео',
  'assets.licensePlate.label': 'Номерной знак',
  'assets.model.label': 'Модель',
  'assets.lastServiceDate.label': 'Дата последнего обслуживания',
  'assets.responsiblePersonContacts.label': 'Контакты ответственного лица',
  'assets.unknown.label': 'Неизвестное поле',
  
  'assets.edit.title': 'Редактирование объекта',
  'assets.selectProject': 'Выберите проект',

  'asset.details.currentStatus': "Текущий статус",
  'asset.details.historicalData': "История",
  'asset.details.sendCommands': "Отправка команд",
  'asset.details.streaming': "Видео",
  'asset.details.doYouWantToDelete': "Вы хотите удалить этот объект?",
  'asset.details.messageWasNotAdded': "Сообщение не доставлено",
  'asset.details.messageAdded': "Сообщение доставлено",
  'asset.details.loading': 'Загрузка...',
  'asset.details.editAsset': 'Редактировать объект',
  'asset.details.deleteAsset': 'Удалить объект',
  'asset.details.status': 'Статус',
  'asset.details.id': 'Идентификатор',
  'asset.details.name': 'Наименование',
  'asset.details.type': 'Тип',
  'asset.details.project': 'Проект',
  'asset.details.group': 'Группа',
  'asset.details.information': 'Информация',
  'asset.details.connectedUAVs': 'Подключенные БВС',
  'asset.details.capabilities': 'Возможности',
  'asset.details.optionalProperties': 'Дополнительные свойства',
  'asset.details.model': 'Модель',
  'asset.details.lastServiceDate': 'Дата последнего сервисного обслуживания',
  'asset.details.responsible': 'Ответственный',
  'asset.details.licensePlate': 'Номер',
  'asset.details.comment': 'Комментарии',
  'asset.details.mileage': 'Километраж',
  'asset.details.today': 'Сегодня',
  'asset.details.thisMonth': 'Месяц',
  'asset.details.total': 'Всего',
  'asset.details.message': 'Сообщение',
  'asset.details.typeMessageHere': 'Наберите сообщение',
  'asset.details.sendMessage': 'Отправить сообщение',
  'asset.details.sentCommands': 'Отправленные команды',
  'asset.details.otherCommands': 'Другие команды',
  'asset.details.startVideoStreaming': 'Начать показ видео',
  'asset.details.stopVideoStreaming': 'Остановить показ видео',

  'assets.historicalData.telemetryData': 'Данные телеметрии',
  'assets.historicalData.timestamp': 'Время',
  'assets.historicalData.altitude': 'Высота',
  'assets.historicalData.speed': 'Скорость',
  'assets.historicalData.location': 'Координаты',
  'assets.historicalData.altitudeM': 'Высота, м',
  'assets.historicalData.course': 'Курс, °',
  'assets.historicalData.speedKmH': 'Скорость, км/ч',
  'assets.historicalData.inAir': 'В полете',
  'assets.historicalData.yes': 'Да',
  'assets.historicalData.no': 'Нет',
  'assets.historicalData.na': 'Нет данных',
  'assets.historicalData.pilotingMode': 'Режим пилотирования',
  'assets.historicalData.diagnosticsFlags': 'Диагностика',
  'assets.historicalData.from': 'Даты',
  'assets.historicalData.filters': 'Фильтры',
  'assets.historicalData.today': 'Сегодня',
  'assets.historicalData.thisMonth': 'Текущий месяц',
  'assets.historicalData.getData': 'Получить данные',
  'assets.historicalData.charts': 'Графики',
  'mapBox.sattelite': 'Спутник',
  'assets.historicalData.noLocationData': 'Координаты отсутствуют',
};
