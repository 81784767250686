export default {
  'mark.Title': 'Метка',
  'mark.name': 'Наименование',
  'mark.radius': 'Радиус (км)',
  'mark.color_radius': 'Цвет радиуса',

  'mark.context.Mark': 'Метка',
  'mark.context.add': 'Добавить',
  'mark.context.addMark': 'Добавить метку',
  'mark.context.editMark': 'Редактировать метку',
  'mark.context.delMark': 'Удалить метку',
  'mark.context.button.OK': 'Добавить',
  'mark.context.button.EDIT': 'Редактировать',
  'mark.context.button.DELETE': 'Удалить',
  'mark.context.button.Cancel': 'Отмена',
  'mark.context.create.success': 'Метка создана!',
  'mark.context.update.success': 'Метка обновлена!',
  'mark.context.delete.success': 'Метка удалена!',
  'mark.context.create.name.required': 'Необходимо ввести название!',
  'mark.context.delete.confirm' : 'Вы уверены, что хотите удалить метку?',
};
