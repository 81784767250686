import { MenuDataItem } from "@ant-design/pro-layout";

export default class Video {
  dashboardOpened: boolean = false;
  streams: any[] = [];
  disabledStreams: string[] = [];

  openDashboard = () => {
    this.dashboardOpened = true;
    this.disabledStreams = [];
  }

  hideDashboard = () => {
    this.dashboardOpened = false;
  }

  isDashboardVisible = (): boolean => this.dashboardOpened;

  setVideoStreams = (streams: any[]) => {
    this.streams = streams;
  }

  getSubMenu = (): MenuDataItem[] => this.streams.map(s => <MenuDataItem>{ key: `v-${s.publicAssetId}`, name: `${s.assetName} (${s.projectName})` });

  isStreamEnabled = (publicAssetId: string): boolean => !this.disabledStreams.includes(publicAssetId);

  toggleStream = (publicAssetId: string, enabled: boolean) => {
    if (enabled) {
      this.disabledStreams = this.disabledStreams.filter(s => s !== publicAssetId);
    } else {
      this.disabledStreams.push(publicAssetId);
    }
  }
}
