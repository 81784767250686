export default {
  'online.layers': 'Online marks',
  'online.height': 'Height',
  'online.wind_ms': 'Wind speed',
  'online.wind_dir': 'Wind direction',
  'online.metr': 'm',
  'online.metr_seconds': 'm/s',

  'online.MultiStream': 'MultiStream',

  'online.assetsFilter.onlyActive': 'Show only active Assets',
  'online.assetsFilter.onlyStreaming': 'Show only video streaming Assets',
  'online.assetsFilter.onlyRevoked': 'Show only revoked Assets',
  'online.assetsFilter.assetType': 'Filter by asset type',

  'online.assetTypes.allAssetTypes': 'All types',
  'online.assetTypes.car.shortname': 'Car',
  'online.assetTypes.car.fullname': 'Car',
  'online.assetTypes.uav.shortname': 'Uav',
  'online.assetTypes.uav.fullname': 'Unmanned Aerial Vehicle (UAV)',
  'online.assetTypes.gcs.shortname': 'Gcs',
  'online.assetTypes.gcs.fullname': 'Ground control station (GCS)',
  'online.assetTypes.incident.shortname': 'Marker',
  'online.assetTypes.incident.fullname': 'Marker',
  'online.assetTypes.beacon.shortname': 'Beacon',
  'online.assetTypes.beacon.fullname': 'Beacon',
  'online.assetTypes.geographics.shortname': 'Geographics',
  'online.assetTypes.geographics.fullname': 'Geographics',
  'online.assetTypes.localServers': '4Z1',
  'online.assetTypes.ptz.fullname': 'Camera',

  'online.assetCard.connected': 'Connected',
  'online.assetCard.disconnected': 'Disconnected',
  'online.assetCard.canSendGPS': 'Can send GPS',
  'online.assetCard.canStreamVideo': 'Can stream video',
  'online.assetCard.canExecuteCommands': 'Can execute commands',
  'online.assetCard.hardwareID': 'Hardware ID',
  'online.assetCard.warnings': 'Warnings',
  'online.assetCard.gcs': 'GCS',
  'online.assetCard.state': 'State',
  'online.assetCard.inAir': 'In Air',
  'online.assetCard.grounded': 'Grounded',
  'online.assetCard.mode': 'Mode',
  'online.assetCard.engineTemp': 'Engine Temp',
  'online.assetCard.activeUAV': 'Active UAV',
  'online.assetCard.сonnectedUAVs': 'Connected UAVs',
  'online.assetCard.speed': 'Speed',
  'online.assetCard.kmh': 'km/h',
  'online.assetCard.course': 'Course',
  'online.assetCard.location': 'Location',
  'online.assetCard.altitude': 'Altitude',
  'online.assetCard.lastUpdated': 'Last Updated',
  'online.assetCard.last1h': 'Last 1 hour',
  'online.assetCard.last8h': 'Last 8 hour',
  'online.assetCard.last24h': 'Last day',
  'online.assetCard.showTrack': 'Show Track',
  'online.assetCard.showVideoStream': 'Show Video Stream',
  'online.assetCard.changeCoordinates': 'Change coordinates',
  'online.assetCard.showVideoStreamFromGCS': 'Show Video Stream from GCS',
  'online.assetCard.openDetailsPage': 'Open Details page',

  'online.multiVideoPlayers.actions.zoomIn': "zoom in",
  'online.multiVideoPlayers.actions.zoomOut': "zoom out",
  'online.fieldOfView': 'Camera visibility area',
  'online.fieldOfViewToggle.show': 'Show camera area',
  'online.fieldOfViewToggle.hide': 'Hide camera area',

};
