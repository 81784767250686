export default {
  'ftp.hint.unknown': 'Данные о полёте ещё не загружались на FTP-сервер',
  'ftp.hint.in_progress': 'Идёт процесс загрузки данных на FTP-сервер',
  'ftp.hint.done': 'Данные успешно загружены на FTP-сервер',
  'ftp.hint.failed': 'В процессе загрузки данных произошла ошибка',

  'ftp.userLoginGCS': 'Логин пользователя GCS',
  'ftp.companyCode': 'Код дочернего общества',
  'ftp.viewShot': 'Вид съёмки',
  'ftp.flightDate': 'Дата полёта',
  'ftp.routeNumber': 'Номер маршрута',
  'ftp.flightNumber': 'Порядковый номер полёта',
  'ftp.teamNumber': 'Номер бригады',
  'ftp.cameraModel': 'Модель фотокамеры',
  'ftp.focus': 'Фокусное расстояние (мм)',
  'ftp.send_to_ftp': 'Отправить на FTP',
  'ftp.send_to_ftp.title': 'Загрузка на FTP',
  'ftp.submit.error': 'Произошла ошибка. Проверьте введенные данные и попробуйте еще раз',
  'ftp.submit.success': 'Успех! Начата Загрузка данных полета на FTP',

  'ftp.validation.required_value': 'Пожалуйста, введите значение',
  'ftp.validation.required_file': 'Пожалуйста, выберите файл',
  'ftp.validation.max_length': 'Превышено допустимое максммальное количество символов - ',
  'ftp.validation.permitted_chars': 'Допустимы только символы латинского алфавита, цифры, дефис и точка',
  'ftp.validation.only_numbers_permitted': 'Допустимы только цифры, дефис и точка',

  'ftp.error.no_flight': 'Что-то пошло не так. Полет не найден.',

  'ftp.form.telemetryTitle': 'Загрузка телеметрии',
  'ftp.form.uploadFile': 'Выбрать файл',
  'ftp.form.send': 'Отправить файл телеметрии',
  'ftp.mm': 'мм',
};
