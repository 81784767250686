export default {
  'tile.Tiles': 'Tiles',
  'tile.Title': 'Title',
  'tile.Title.new': 'Title new tile',
  'tile.Title.empty': 'Enter the title',
  'tile.Title.Group': 'Group title',
  'tile.Title.Tile': 'Tile title',
  'tile.AddGroup': 'Add group',
  'tile.AddTile': 'Add tile',
  'tile.Title.visible': 'Visible',
  'tile.error.nottype': 'The file is not in the correct format.',
  'tile.Sort': 'Sort',
  'tile.minzoom': 'Min zoom',
  'tile.Modal.OK': 'OK',
  'tile.Modal.OK2': 'Enable',
  'tile.Modal.Title': 'This layers disabled',
  'tile.Modal.SubTitle': 'to enable a layer, select them and click Enable',
};
