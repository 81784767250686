export default {
  'login.submit': 'Submit',
  'login.username.placeholder': 'Username',
  'login.username.required': '«Username» required',
  'login.password.placeholder': 'Password',
  'login.password.required': '«Password» required',
  'login.rememberMe': 'Remember me',
  'login.username.password.error': 'Incorrect login or password',
  'login.username.password.success': 'Successful authorization',
  'login.accountLogin.errorMessage': 'Error Login',
}
