export default {
  'dtoe.CreateTask': 'Создать загрузку',
  'dtoe.Submit': 'Отправить',
  'dtoe.Polygon': 'Выбрать произвольный полигон',
  'dtoe.Extent': 'Выбрать прямоугольную область',
  'dtoe.Levels': 'Уровни детализации:',
  'dtoe.Levels.required': 'Нужно выбрать хотя бы один уровень',
  'dtoe.Source': 'Источник:',
  'dtoe.Source.required': 'Нужно выбрать источник',
  'dtoe.Source.ArcGIS': 'ArcGIS',
  'dtoe.Source.Bing': 'Bing',
  'dtoe.Source.Google': 'Google',
  'dtoe.Source.Yandex': 'Yandex',
  'dtoe.TileType': 'Тип данных:',
  'dtoe.TileType.required': 'Нужно выбрать тип данных',
  'dtoe.TileType.aer': 'Базовый растровый слой',
  'dtoe.TileType.dem': 'Карта высот',
  'dtoe.TileType.vgm': 'Маска растительности',
  'dtoe.TileType.ofp': 'Ортофотоплан',
  'dtoe.error.Submit': 'При отправке произошла ошибка, попробуйте снова.',
  'dtoe.Submit.Success': 'Задача на загрузку успешно поставлена в очередь.',
  'dtoe.Cancel': 'Отменить',
  'dtoe.State.unknown': 'Н/Д',
  'dtoe.State.undefined': 'Н/Д',
  'dtoe.State.pending': 'Ожидание',
  'dtoe.State.executing': 'Загрузка',
  'dtoe.State.complete': 'Готово',
  'dtoe.State.cancelling': 'Отмена',
  'dtoe.State.cancelled': 'Отменено',
  'dtoe.Cancellation.Success': 'Загрузка отменена',
  'dtoe.error.Cancellation': 'При отмене загрузки произошла ошибка, попробуйте снова.',
  'dtoe.Creator': 'Инициатор',
  'dtoe.Created': 'Создана',
  'dtoe.State': 'Статус',
  'dtoe.Progress': 'Прогресс',
  'dtoe.Description': 'Комментарии:',
  'dtoe.Description.max': 'Комментарии не должны превышать 250 символов',
  'dtoe.Or': 'или',
  'dtoe.error.EmptyPolygon': 'Необходимо выбрать область загрузки.',
  'dtoe.Button.Back': 'Назад',
  'dtoe.Upload': 'Выгрузить',
  'dtoe.UploadTitle': 'Выгрузить в формате:',
  'dtoe.error.EmptyFormats': 'Необходимо выбрать как минимум один выходной формат',
  'dtoe.Layer': 'Слой:',
  'dtoe.Download': 'Скачать',
}
