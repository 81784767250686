export default {
  'map.Tiles': 'Tiles',
  'map.OSM': 'Open street map',
  'map.Measure.length': 'Length',
  'map.Measure.area': 'Area',
  'map.Measure.clear': 'Clear',
  'map.Measure.triangle': 'Artillery Triangle',
  'map.SearchOnArea': 'Search on area',
  'map.SearchResults': 'Search results',
  'map.SearchByCoordinates': 'Search by coordinates',
  'map.Search': 'Search',
  'map.SearchEmptyMessage': 'Not found',
  'map.SearchLengthErrorMessage': 'Maximum number of characters exceeded',
  'map.zoomIn': 'zoom in',
  'map.zoomOut': 'zoom out',
}
