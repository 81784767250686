export default {
  'massload.BACK_TO_UPLOAD': 'К загрузке файлов',
  'massload.File Name': 'Имя файла',
  'massload.Created At': 'Создано',
  'massload.Type': 'Тип файла',
  'massload.Actions': 'Действия',
  'massload.DownloadServiceLink': 'Скачать агентa загрузки',
  'massload.NoService': 'Агент не активен',
  'massload.spaceinfo': 'Дисковое пространство',
  'massload.loading.telemetry': 'Загрузка телеметрии',
};
