export default {
  'user.Profile': 'Profile',
  'user.ChPass': 'Change password',
  'user.nickname': 'Nickname',
  'user.password': 'Password',
  'user.password.current': 'Current',
  'user.password.new': 'New',
  'user.password.confirm': 'Confirm',
  'user.date': 'Date',
  'user.Options': 'Options',
  'user.Options.blank': 'Open on new window',
  'user.password.validate': '',
  'user.password.confirm.error': 'The two passwords that you entered do not match!',
  'user.password.current.empty': 'Please enter your current password!',
  'user.password.new.empty': 'Please enter new password!',
  'user.password.confirm.empty': 'Please confirm your password!',
  'user.password.change.success': 'Password changed',
  'user.password.change.current.error': 'Сurrent password does not match',
  'user.password.change.server_error': 'Try again later',
}
