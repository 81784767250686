export default {
  'flight.name': 'Наименование',
  'flight.routes': 'Все маршруты',
  'flight.users': 'Все пользователи',
  'flight.showAll': 'Показать все',
  'flight.edit': 'Редактировать полёт',
  'flight.NewFlight': 'Новый полёт',

  'flight.flights_update_success': 'Данные успешно обновлены',
  'flight.flights_update_error': 'Ошибка обновления',
  'flight.empty_new_flight': 'Новый полёт не может быть пустым',

  'flight.NO_FLIGHT': 'Полёт не найден',
  'flight.Edit_Name_Flight': 'Редактирование название полёта',
  'flight.flights_delete_success': 'Полёт успешно удалён',
};
