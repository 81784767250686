import type {CurrentUser} from "@/app";

export default function access(initialState: { currentUser?: CurrentUser | undefined }) {
  const { currentUser } = initialState || {};
  const permits: any = {
    canAdmin: currentUser && currentUser.access === 'admin',
    zalaflight: false,
    zalaimagetile: false,
    zalavideo: false,
    zalaviolation: false,
    zalaonline: false,
    zalaortophoto: false,
    zalagaz: false,
    zalareport: false,
    zalaorder: false,
    'zalaortophoto/cdreport': false,
    'zalaimagetile/download': false,
    'vprf': false,
    'traffic': false,
    'dtoe': false,
  };
  
  if(currentUser) {
    currentUser.permits.map((permit: any) => {
      permits[permit.name] = permit.access;
    });
  }

  return permits;
}
