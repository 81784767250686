// @ts-nocheck
// This file is generated by Umi automatically
// DO NOT CHANGE IT MANUALLY!
import HomeOutlined from '/builds/4z1-x/frontend/redrock.frontend/node_modules/@ant-design/icons/es/icons/HomeOutlined';
import CloudUploadOutlined from '/builds/4z1-x/frontend/redrock.frontend/node_modules/@ant-design/icons/es/icons/CloudUploadOutlined';
import GlobalOutlined from '/builds/4z1-x/frontend/redrock.frontend/node_modules/@ant-design/icons/es/icons/GlobalOutlined';
import AlertOutlined from '/builds/4z1-x/frontend/redrock.frontend/node_modules/@ant-design/icons/es/icons/AlertOutlined';
import VideoCameraOutlined from '/builds/4z1-x/frontend/redrock.frontend/node_modules/@ant-design/icons/es/icons/VideoCameraOutlined';
import PictureOutlined from '/builds/4z1-x/frontend/redrock.frontend/node_modules/@ant-design/icons/es/icons/PictureOutlined';
import HeatMapOutlined from '/builds/4z1-x/frontend/redrock.frontend/node_modules/@ant-design/icons/es/icons/HeatMapOutlined';
import UnorderedListOutlined from '/builds/4z1-x/frontend/redrock.frontend/node_modules/@ant-design/icons/es/icons/UnorderedListOutlined';
import FileExclamationOutlined from '/builds/4z1-x/frontend/redrock.frontend/node_modules/@ant-design/icons/es/icons/FileExclamationOutlined';
export default { HomeOutlined, CloudUploadOutlined, GlobalOutlined, AlertOutlined, VideoCameraOutlined, PictureOutlined, HeatMapOutlined, UnorderedListOutlined, FileExclamationOutlined };
