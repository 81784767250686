export default {
  'ofp.name': 'Наименование',
  'ofp.title': 'Ортофотоплан',
  'ofp.datetime': 'Дата',
  'ofp.ofpicons': 'Метки',
  'ofp.Compare': 'Сравнить',
  'ofp.Action': 'Действия',
  'ofp.OFP_MISSING': 'Ортофотоплан удален',
  'ofp.Cdreport.button': 'ГеоДинамика',
  'ofp.Cdreport.SearchArea': 'Область отчёта',
  'ofp.Cdreport.Classes': 'Классы',
  'ofp.Cdreport.Segments': 'Сегментация',
  'ofp.Cdreport.Open': 'PDF',
  'ofp.cdreport.name': 'Наименование',
  'ofp.cdreport.ofp_id_1': 'Левое окно CD',
  'ofp.cdreport.ofp_id_2': 'Правое окно CD',
  'ofp.сdreport.Confirm update': 'Уверены в выбранных ортофотопланах?',
  'ofp.сdreport.VIEW_PDF': 'Просмотр PDF',
  'ofp.сdreport.VIEW_OFP': 'Просмотр на ортофотопланах',
  'ofp.Orthomosaic processing': 'Обработка GeoTIFF',
};
